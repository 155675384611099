$env: production;
@import "enlarge";

body, .form-control, .multiselect__placeholder, .multiselect__element, .multiselect input {
    font-size: 12px;
}

.multiselect__tags {
    padding: 0.375rem 0.75rem !important;
}
.multiselect__placeholder {
    margin-bottom: 0 !important;
    color: #495057 !important;
}

.modal {
    background-color: rgba(0,0,0,0.2);
}

textarea {
    resize: none;
}

.modal-backdrop {
    background-color: rgba(0,0,0,0.2);
}

.required > label:after {
    content: "*";
    color: #ff0000;
}

.currecny-symbole {
    position: relative;
    padding-left: 10px;
    margin: 0px;
    line-height: 1;
}

.currecny-symbole::before {
    content: '$';
    font-size: 16px;
    position: absolute;
    top: 2px;
    left: 75px;
}

.subscription-page {
    -ms-flex-align: center;
    align-items: center;
    background: #e9ecef;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    margin-top: 56px;
    padding: 1rem 0;
}

.select-btn-custom {
    max-width: 380px;
    width: 100%;
    margin: 0 auto;
}

.catalog-index-image {
    cursor: pointer;
    width: 50px;
    height: 50px
}

.catalog-edit-image {
    cursor: pointer;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-top: 10px
}

.catalog-modal-class {
    width: 100%;
    height: 100%
}

.catalog-show-image {
    cursor:pointer;
    width:80px;
    height:80px;
    border-radius:50%;
    margin-top:10px;
}

.close {
    position: absolute;
    z-index: 1;
}

.image-modal {
    position: relative;
    width: 100%;
}

.sync-button:hover {
    cursor: pointer;
}

.dropdown-menu {
    .category-list, .recipe-list {
        max-height: 10rem;
        overflow-y: auto;
        width: 18rem;
        font-size: 0.8rem;
    }
}
.dropdown-menu > div {
    max-height: 10rem;
    overflow-y: auto;
    width: 18rem;
    font-size: 0.8rem;
}
.multiselect .multiselect__single {
    font-size: 12px !important;
  }

#category-card {
  display: flex;
  color: grey;
  justify-content: center;
  height: 100vh;
}

.add-multiselect-wrapper{
  .multiselect__content-wrapper{
    max-height: 275px !important;
  }
}

.edit-multiselect-wrapper{
  .multiselect__content-wrapper{
    max-height: 200px !important;
  }
}

.invalid-feedback, .invalid-error {
    font-size: 10px !important;
    font-weight: 500 !important;
}

.outline-danger {
    border: 1px solid #ff0000 !important;
}

#__BVID__10___BV_modal_outer_{
    z-index: 1040;
}

.modal.modal-center {
    padding: 0 !important;
}

.modal-center > div {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
}

.image-field {
    label.invalid-error {
        display: block;
        text-align: center;
        margin: -15px 0 15px 0;
    }
}

.ingredient-table-wrapper {
    max-height: 300px;
    overflow: auto;

    table {
        margin: 0;
    }
}
.multiselect__tags{
    border: 1px solid #ced4da !important;
    padding-bottom: 0 !important;
}
//date filter styling for Recipe history and Recipe Worklist 
.date-filter {
    margin: -10px 10px 0 10px;
    display: flex;
    
    label {
        justify-content: flex-start;
    }
    input {
        padding: 8px;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        font-size: 12px;
        color: #495057;
        min-width: 140px;
        min-height: 36px;
        background: transparent;
    }
}
// for spacing between buttons (GLOBAL) 
.btn-group .btn:nth-of-type(even) {
    margin: 0 0.25rem;
}

//for restricting body scroll when modal is open
body:has(.modal.show) {
    overflow: hidden;
}

//for logo wrapper 
.logo-wrapper {
    max-width: 200px;
    margin: 0 auto;
}

@media(min-width: 1280px) {
    .input-header .button-group {
      text-align: right;
    }
}

@media(max-width: 1279px) {
    .input-header {
      flex-direction: column;
    }
}

@media(max-width: 992px) {
    .img-wrapper {
        max-width: 380px;
        padding-top: 40% !important;
    }
    .add-recipe, .edit-recipe {
        .details-field {
            order: 2;
        }
        .image-field {
            order: 1;
        }
    }
}

@media(max-width: 767px) {
    .date-filter {
      margin: 10px 10px 0 0;
    }
}

@media (min-width: 991px) and (max-width: 1100px) {
    .recipe-yield-table > div:nth-child(1) {
      max-width: 100%;
      flex: 0 0 100%;
    }
    .recipe-yield-table > div:nth-child(2), .recipe-yield-table > div:nth-child(3) {
      max-width: 50%;
      flex: 0 0 50%;
    } 
  }

